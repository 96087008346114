<template>
  <div class="about">
    <back-arrow />

    <div class="mountains stick-to-floor animate__animated animate__slideInRight">
      <svg xmlns="http://www.w3.org/2000/svg" width="1436.399" height="554.243" viewBox="0 0 1436.399 554.243">
        <g id="Mountains" transform="translate(-564.658 -450.757)">
          <path id="Base_mountain" data-name="Base mountain" d="M4838.526,3701.943,4861.3,3655l34.7-45.75,51.029-53.482,21.433-40.595,32.658-25.13,11.227-30.929,60.214-57.992,56.132-26.419,42.865-67.013,61.375-47.358v-15.79l26.2-13.529,74.449-83.315,370.192,261.331,55.176-29.07,3.925-15.861,26.692-7.435,26.692-24.783,40.095-19.184L6022.614,3474l173.713,144.916,55,83.026Z" transform="translate(-4273.868 -2696.943)" fill="#e3a45a"/>
          <path id="Left_Shadow" data-name="Left Shadow" d="M5224.048,3147.7l24.146,36.133-12.073,14.157,20.7,8.712-8.624,37.025,32.771,16.334-13.8,26.135,39.67,13.068v46.826l51.138,20.487,12.18,62.264,30.383,20.7,8.592,26.134,15.921,28.481,12.021,74.376,16.8,34.391,24.793,39.989,23.994-57.584v-38.39l11.29-20.113,82.658,4.517,50.253,15.6-27.033-32.231-20.174-26.823,17.663-3.573,24.9,6.374-9.1-8.6-6.018-5.683-22.558-10.727-21.3-4.081L5561,3460.711l-23.419-11.168,59.027-40.293-98.235-79.927-37.627-19.944-37.163-23.182-46.454-36.356-6.968-9.972-31.589-20.237Z" transform="translate(-4167.3 -2696.943)" fill="#7b664d"/>
          <path id="Right_Shadow" data-name="Right Shadow" d="M5636.063,3352.6l7.136,38.082-7.136,11.6,25.507,7.088-8.164,10.31,24.494,19.331-8.165,15.465,28.577,15.464-11.227,61.859h33.68l-7.145,14.176,40.824,3.866-5.1,23.842,60.586,77.608,32.063,14.723V3688.1h26.234l16.432,53.663H6054.6l-60.979-70.756-72.412-67.113-62.2-54.051-53.726-39.726-55.948-61.685Z" transform="translate(-4053.548 -2736.761)" fill="#7b664d"/>
        </g>
      </svg>
    </div>

    <div class="wrapper foreground tumblr-wrapper">
      <section class="introduction">
        <h1>About me</h1>
        <p>Hi! My name is Jeroen.</p>
        <p>Thank you for visiting my personal website, which hopefully introduces you to me and my work!</p>
        <p>This website is a personal coding project, in which I am trying to intergrate various libraries and APIs.</p>
        <br />
        <h3>A Brief History...</h3>
        <p>I am originally from the Netherlands, but currently reside in Finland. Born in a village called Heiloo, I eventually moved out of there to see more of the world. First my journey took me to Amsterdam, where I studied Communication and Multimedia Design. This started my career as a software developer, but also sparked my interest in game development.</p>
        <p>After finish a Bachelor's degree there, I decided I wanted to study further and found my way to Tallinn, Estonia. Here I completed a Master's degree Cum Laude in Digital Learning Games. I came to love this country and greatly enjoyed my time there. Maybe the real treasure was the friends we made along the way.</p>
        <p>My love for the Nordic countries and snow, as well as relationship and career reasons, eventually took me to Finland to settle down.</p>
        <br />
        <h3>Current activities</h3>
        <p>Now, I spend most of my time working as a full-stack software developer, on hobby game projects and occasional art projects. Aside from this, I very much enjoy reading books and watching series, especially science-fiction and fantasy.</p>
        <p>My hobby game projects usually involve Unity, unless I am making a physical board game or card game. I enjoy taking part in Game Jams, and have been active in the Global Game Jam for the past years. There, I try to vary my role, usually ending up as the developer or game designer.</p>
        <p>As Software Developer, I am currently mostly working with Vue. I mostly enjoy Front-end development as well as UX-design here.</p>
        <p>Aside from these digital activities, I also like the venture out of my house. When I do, I spend a lot of time bouldering in the nearest bouldering gyms, doing some winter sport activities or taking relaxing walks in nature.</p>
        <br />
        <p>Additionally, I like to travel (although that's a bit harder now), and you can find my travel blog below!</p>
      </section>
      <section class="tumblr-text">
        <p v-if="!posts.length">Loading blog...</p>
        <div class="blog-posts">
          <div v-for="post in posts" v-bind:key="post.id">
            <h2 class="tumblr-blog-title">{{post.title}}</h2>
            <section class="tumblr-blog-post" v-html="post.body"></section>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
//import {database} from '../firebase'
import {tumblrClient} from '../tumblr'
import BackArrow from '../components/BackArrow.vue'

export default {
  name: 'About',
  components: {BackArrow},
  data () {
    return {
      education: {},
      posts: []
    }
  },
  methods: {
    getBlogPosts (blogURL) {
      tumblrClient.blogPosts(blogURL, (err, data) =>  {
        //console.log(data);
        const reversePosts = data.posts.reverse();
        this.posts = reversePosts;
      });
    },
  },
  mounted () {
    this.getBlogPosts('jeroen-bosch.tumblr.com') //'roocher-games.tumblr.com'
    // var context = this;
    // database.ref('/CV/education/').once('value').then(result => {
    //   console.log(result.val());
    //   context.education = result.val();
    // });
  },
}
</script>

<style lang="scss">
  @import '@/scss/_variables.scss';

  .about {
    display: flex;

    .mountains {
      margin-bottom: -10px;
      right: 0;
      margin-right: scale_width(-120);
      height: scale_height(600);

      svg {
        height: 100%;
        width: fit-content;
      }
    }

    .tumblr-wrapper {
      width: 540px;

      .introduction {
        background: rgba(255,255,255,0.75);
        margin: 15px 0;
        padding: 15px 0;
        border-radius: 7.5px;
      }

      .blog-posts div {
        background: white;
        margin: 15px 0;
        padding: 15px 0;
        border-radius: 7.5px;
      }

      .tumblr-text {
        font-family: "Favorit", "Helvetica Neue", "HelveticaNeue", Helvetica, Arial, sans-serif;
      }

      h1, h2, h3, h4, p {
        padding: 0 20px; 
      }
    }
  }
</style>
